




























import { Vue, Component } from "vue-property-decorator";

@Component({
  metaInfo() {
    return {
      title: "Parolamı unuttum"
    };
  }
})
export default class ForgotPasswordView extends Vue {
  form = {
    email: ""
  };

  alert = false;
  message = "";
  error = false;

  get emailRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  validate(): boolean {
    const form = this.$refs.form as HTMLFormElement;
    return form.validate();
  }

  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();
  }

  resetValidation(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  send(): void {
    if (this.validate()) {
      console.log("Send");
    }
  }

  mounted(): void {
    this.$emit("setTitle", "Parolamı unuttum");
  }
}
